@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap');

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --white: #fff;
  --black: #000;
  /* new css */
  --table-head: #36383f;
  --table-delete-edit-middle-line: #d6e2e2;
  --link-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --pagenation-color: #b0b0b0;
  --light-orange: #fe6e4c;
  --light-yellow: #ffeab4;
  --yellow: #ffb800;
  --inactive: #f0f0f0;
  --notification-subTitle: #858585;
  --notification-read: rgba(242, 245, 245, 0.8);
}

a {
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.MuiInputLabel-root.Mui-disabled{
  color:#333 !important;
}
.MuiInputBase-input.Mui-disabled{
  -webkit-text-fill-color:#333 !important;
  color:#333 !important;
}
.MuiButton-contained.Mui-disabled{
  color:#333 !important;
  background-color: #777777cf !important;
}